const mixin = {
    methods: {
        getAvatar(account) {
            if (!(account.profile?.avatar)) {
                return require('../assets/img/profile.jpg')
            }

            return (account.profile.avatar.indexOf('https://') === -1) ? `${process.env.VUE_APP_ROOT_API}${account.profile.avatar}` : account.profile.avatar;
        },
    }
}

export default mixin;
