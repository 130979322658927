import axios from 'axios';

const request = (app) => {
    const prepareQueryParams = (url, params = {}) => {
        if (Object.keys(params).length) {
            let query = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');

            url = `${url}?${query}`;
        }

        return url;
    }

    const mergeDeep = (target, source) => {
        let isObject = (obj) => obj && typeof obj === 'object';

        if (!isObject(target) || !isObject(source)) {
            return source;
        }

        Object.keys(source).forEach(key => {
            let targetValue = target[key];
            let sourceValue = source[key];

            if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
                target[key] = targetValue.concat(sourceValue);
            } else if (isObject(targetValue) && isObject(sourceValue)) {
                target[key] = mergeDeep(Object.assign({}, targetValue), sourceValue);
            } else {
                target[key] = sourceValue;
            }
        });

        return target;
    }

    // Implements the axios config and instance interceptors.
    let instance = (options = {}) => {
        let config = {
            baseURL        : process.env.VUE_APP_ROOT_API,
            withCredentials: false,
            headers        : {
                'Content-Type': 'application/json',
                'Accept'      : 'application/json',
                'X-AUTH-TOKEN': 'anon.',
            }
        };

        let instance = axios.create(mergeDeep(config, options));

        instance.interceptors.request.use((config) => {
            return config;
        }, (error) => {
            return Promise.reject(error);
        });

        instance.interceptors.response.use(response => {
            return Promise.resolve(response.data);
        }, (error) => {
            return (error?.response?.data) ? Promise.reject(error.response.data) : Promise.reject(error.message);
        });

        return instance;
    };

    app.config.globalProperties.$request = {
        get(url, params = {}, options = {}) {
            return instance(options).request({method: 'get', url: prepareQueryParams(url, params)});
        },

        post(url, data = {}, params = {}, options = {}) {
            return instance(options).request({method: 'post', url: prepareQueryParams(url, params), data});
        },

        delete(url, data = {}, params = {}, options = {}) {
            return instance(options).request({method: 'delete', url: prepareQueryParams(url, params), data});
        },
    };
}

export default request;
