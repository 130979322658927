const mixin = {
    methods: {
        isWebView() {
            let userAgent = window.navigator.userAgent.toLowerCase();

            return /com.joinkiddo\/1\.x/.test(userAgent);
        },

        isMobileDevice() {
            let userAgent = window.navigator.userAgent.toLowerCase();

            return (/android|webos|iphone|ipad|ipod|blackberry|IEMobile|Opera Mini/i.test(userAgent));
        },

        isMobileAndroid() {
            let userAgent = window.navigator.userAgent.toLowerCase();

            return (/android/i.test(userAgent));
        },

        isMobileIos() {
            let userAgent = window.navigator.userAgent.toLowerCase();

            return (/iphone|ipad|ipod/i.test(userAgent));
        },
    }
}

export default mixin;
