<template>
    <div class="home">
        <MobileAppBadge :class="(isEdoositter) ? 'is-edositter' : null "/>

        <div id="section-hero" class="has-background-danger-light" :class="(isEdoositter) ? 'is-edositter' : null ">
            <div class="container is-max-desktop">
                <!-- navbar -->
                <Navigation/>

                <section class="hero">
                    <div class="hero-body">
                        <div class="">
                            <div class="title is-size-1-desktop is-size-3-touch">
                                <div class="has-text-weight-bold has-text-danger">
                                    Găsește oricând un babysitter de încredere pentru copilul tău
                                </div>
                            </div>
                            <div class="subtitle">
                                <strong>Babysitteri instruiți</strong>, la câteva click-uri distanță, atunci când ai cel
                                mai mult nevoie.
                            </div>
                        </div>
                    </div>
                </section>

                <div class="cover-image-wrapper">
                    <img src="../assets/img/baloons.svg" alt="baloons" class="baloons-image">
                </div>

            </div>
        </div>

        <div id="section-numbers" class="has-background-white" :class="(isEdoositter) ? 'is-edositter' : null ">
            <div class="container is-max-desktop">
                <div class="section">
                    <nav class="level">
                        <div class="level-item has-text-centered">
                            <div>
                                <p class="title is-size-1 has-text-weight-bold has-text-danger">
                                    <CountUp :delay="1000" :endVal="numbers.hours" :options="countup"/>
                                </p>
                                <p class="is-size-6">de ore livrate</p>
                            </div>
                        </div>
                        <div class="level-item has-text-centered">
                            <div>
                                <p class="title is-size-1 has-text-weight-bold has-text-danger">
                                    <CountUp :delay="1000" :endVal="numbers.providers" :options="countup"/>
                                </p>
                                <p class="is-size-6">babysitteri instruiți</p>
                            </div>
                        </div>
                        <div class="level-item has-text-centered">
                            <div>
                                <p class="title is-size-1 has-text-weight-bold has-text-danger">
                                    <CountUp :delay="1000" :endVal="numbers.consumers" :options="countup"/>
                                </p>
                                <p class="is-size-6">părinți fericiți</p>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>

        <div id="section-reviews" class="has-background-link-light" :class="(isEdoositter) ? 'is-edositter' : null ">
            <div class="container is-max-desktop">
                <div class="section">
                    <div class="is-size-2 mb-3">
                        <img src="../../src/assets/img/growth.svg">
                    </div>
                    <div class="is-size-2-desktop is-size-4-touch has-text-weight-bold has-text-danger mb-6">
                        Ce spun părinții despre babysitterii KIDDO?
                    </div>

                    <div class="columns">
                        <div class="column is-flex is-align-items-center">
                            <div class="is-flex is-align-items-center is-flex-direction-column is-size-2 mb-3">
                                <div class="has-text-weight-bold">Excelent</div>
                                <div class="is-flex is-align-items-center">
                                    <Rating :score="reviews.score" :stars="5"/>
                                    <div class="has-text-weight-bold">{{ reviews.score }}/5</div>
                                </div>
                                <div class="has-text-centered is-size-4 has-text-black-bis">
                                    Bazat pe <strong>{{ reviews.count }}</strong> recenzii ale părinților din
                                    comunitate.
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="smartphone">
                                <div class="inner-content">
                                    <div v-if="reviews && reviews.items" class="reviews-slider">
                                        <template v-for="(item, index) in reviews.items" :key="index">
                                            <div class="card mb-5">
                                                <div class="card-content">
                                                    <div class="media">
                                                        <div class="media-left">
                                                            <figure class="image is-48x48">
                                                                <img :src="getAvatar(item.account)" class="is-rounded"
                                                                     alt="avatar">
                                                            </figure>
                                                        </div>
                                                        <div class="media-content">
                                                            <div class="title is-5">{{ item.account.fullName }}</div>
                                                            <div class="is-flex is-align-items-center subtitle is-6">
                                                                <Rating :score="item.score" :stars="5"/>
                                                                <div class="">{{ item.score }}/5</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="content">
                                                        {{ item.body }}
                                                    </div>

                                                    <div class="media">
                                                        <div class="media-left">
                                                            <figure class="image is-24x24">
                                                                <img :src="getAvatar(item.reviewer)" class="is-rounded"
                                                                     alt="avatar">
                                                            </figure>
                                                        </div>
                                                        <div class="media-content is-flex is-align-items-center">
                                                            <div class="is-5 mr-3">{{ item.reviewer.fullName }}</div>
                                                            <div class="is-5 mr-3">&bullet;</div>
                                                            <div class="is-5">
                                                                {{ formatRelativeUnixDate(item.created) }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="is-size-5">
                        <div class="has-text-centered mb-5">
                            Intră în contul KIDDO pentru a vedea toate recenziile.
                        </div>
                        <div>
                            <button @click="handleLoginClick()" class="button is-rounded is-danger is-fullwidth">
                                Intră în cont
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="section-consumers" class="has-background-white" :class="(isEdoositter) ? 'is-edositter' : null ">
            <div class="container is-max-desktop">
                <div class="section">
                    <div class="is-size-2 mb-3">
                        <img src="../assets/img/parents.svg">
                    </div>
                    <div class="title mb-6 is-size-2-desktop is-size-4-touch has-text-weight-bold has-text-danger">
                        Părinții aleg KIDDO pentru că suntem…
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="box has-background-link-light">
                                <div class="is-size-2 mb-3">
                                    <img src="../assets/img/certified.svg">
                                </div>
                                <div class="is-size-4 mb-3">
                                    De încredere
                                </div>
                                <div class="content">
                                    Selectăm riguros babysitterii, verificăm identitatea și le oferim cunoștințe cu
                                    profesioniști din domeniul childcare.
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="box has-background-link-light">
                                <div class="is-size-2 mb-3">
                                    <img src="../assets/img/on-demand.svg">
                                </div>
                                <div class="is-size-4 mb-3">
                                    La cerere
                                </div>
                                <div class="content">
                                    Găsește oricând un babysitter de încredere pentru copilul tău doar atunci când ai
                                    nevoie, pe ore și imediat.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column">
                            <div class="box has-background-link-light">
                                <div class="is-size-2 mb-3">
                                    <img src="../assets/img/intuitive.svg">
                                </div>
                                <div class="is-size-4 mb-3">
                                    Ușor de accesat
                                </div>
                                <div class="content">
                                    Aplicație intuitivă, securizată și rapidă, direct în telefonul tău.
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="box has-background-link-light">
                                <div class="is-size-2 mb-3">
                                    <img src="../assets/img/money-save.svg">
                                </div>
                                <div class="is-size-4 mb-3">
                                    Accesibili ca preț
                                </div>
                                <div class="content">
                                    Ai babysitteri cu experiență vastă, cunoștințe și la de preț accesibil.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <button @click="handleRegisterClick()" class="button is-rounded is-danger is-fullwidth">
                            Creează cont
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div id="section-how-it-works" class="has-background-link-light" :class="(isEdoositter) ? 'is-edositter' : null ">
            <div class="container is-max-desktop">
                <div class="section">
                    <div class="is-size-2 mb-3">
                        <img src="../assets/img/phone-call.svg">
                    </div>
                    <div class="is-size-2-desktop is-size-4-touch has-text-weight-bold has-text-danger mb-6">
                        Cum funcționează?
                    </div>

                    <div class="columns">
                        <div class="column is-flex is-align-items-center">
                            <div class="is-flex is-flex-direction-column mb-3">
                                <div class="title is-size-2 has-text-danger">1.</div>
                                <div class="is-size-6 has-text-black-bis content">
                                    Alege tipul cererii tale.
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <img src="../../src/assets/img/how-to/1.png">
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column is-hidden-touch">
                            <img src="../../src/assets/img/how-to/2.png">
                        </div>
                        <div class="column is-flex is-align-items-center">
                            <div class="is-flex is-flex-direction-column mb-3">
                                <div class="title is-size-2 has-text-danger">2.</div>
                                <div class="is-size-6 has-text-black-bis content">
                                    Indică data și ora când ai nevoie de babysitter.
                                </div>
                            </div>
                        </div>
                        <div class="column is-hidden-desktop">
                            <img src="../../src/assets/img/how-to/2.png">
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column is-flex is-align-items-center">
                            <div class="is-flex is-flex-direction-column mb-3">
                                <div class="title is-size-2 has-text-danger">3.</div>
                                <div class="is-size-6 has-text-black-bis content">
                                    Revizuiește detaliile și plasează cererea.
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <img src="../../src/assets/img/how-to/3.png">
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column is-hidden-touch">
                            <img src="../../src/assets/img/how-to/4.png">
                        </div>
                        <div class="column is-flex is-align-items-center">
                            <div class="is-flex is-flex-direction-column mb-3">
                                <div class="title is-size-2 has-text-danger">4.</div>
                                <div class="is-size-6 has-text-black-bis content">
                                    Alege babysitterul potrivit din cei disponibili și bucură-te de câteva ore doar pentru tine.
                                </div>
                            </div>
                        </div>
                        <div class="column is-hidden-desktop">
                            <img src="../../src/assets/img/how-to/4.png">
                        </div>
                    </div>

                    <div>
                        <button @click="handleRegisterClick()" class="button is-rounded is-danger is-fullwidth">
                            Creează cont
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div id="section-benefits" class="has-background-white" :class="(isEdoositter) ? 'is-edositter' : null ">
            <div class="container is-max-desktop">
                <div class="section">
                    <div class="is-size-2 mb-3">
                        <img src="../assets/img/community.svg">
                    </div>
                    <div class="title mb-6 is-size-2-desktop is-size-4-touch has-text-weight-bold has-text-danger">
                        Beneficiile tale
                    </div>

                    <div class="table-container">
                        <table class="table is-fullwidth">
                            <thead>
                            <tr>
                                <th></th>
                                <th class="has-text-centered">
                                    <IncognitoIcon/>
                                    <div>Alți babysitteri</div>
                                </th>
                                <th class="has-text-centered">
                                    <LogoIcon/>
                                    <div>Babysitter KIDDO</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in benefits" :key="index">
                                <td v-html="item.name"></td>
                                <td class="has-text-centered">
                                    <span class="mdi mdi-close has-text-danger"/>
                                </td>
                                <td class="has-text-centered">
                                    <span class="mdi mdi-check-circle-outline has-text-success"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <button @click="handleRegisterClick()" class="button is-rounded is-danger is-fullwidth">
                            Creează cont
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer has-background-grey-dark" :class="(isEdoositter) ? 'is-edositter' : null ">
            <Footer/>
        </div>

        <div v-if="isEdoositter" class="modal is-active">
            <div class="modal-background"></div>
            <div class="modal-content">
                <div class="is-size-1 has-text-white mb-3">
                    KIDDO a devenit EdooSitter
                </div>
                <div>
                    <button @click="handleEdooSitterClick()" class="button is-rounded is-danger is-fullwidth">
                        <span class="mr-2">EdooSitter</span>
                        <span class="mdi mdi-arrow-right"/>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Rating from "../components/Rating";
    import DateMixin from "../mixins/DateMixin";
    import CountUp from "../components/CountUp";
    import IncognitoIcon from "../components/icons/IncognitoIcon";
    import LogoIcon from "../components/icons/LogoIcon";
    import Navigation from "../components/blocks/Navigation";
    import RouteMixin from "../mixins/RouteMixin";
    import Footer from "../components/blocks/Footer";
    import ProfileMixin from "../mixins/ProfileMixin";
    import MobileAppBadge from "../components/MobileAppBadge";

    export default {
        name: 'Home',

        components: {
            MobileAppBadge,
            Footer,
            Navigation,
            LogoIcon,
            IncognitoIcon,
            CountUp,
            Rating,
        },

        mixins: [
            DateMixin,
            RouteMixin,
            ProfileMixin,
        ],

        created() {
            this.setExternalData();
            this.handleRedirect();
        },

        data() {
            return {
                isEdoositter: (process.env.VUE_APP_EDOOSITTER === 'true'),

                reviews: {
                    count: 0,
                    items: [],
                    score: 5,
                },

                numbers: {
                    hours    : 0,
                    providers: 0,
                    consumers: 0,
                },

                benefits: [
                    {
                        name : '<b>De încredere:</b> verificare identitate babysitter',
                        black: '❌',
                        kiddo: '✅',
                    },
                    {
                        name : '<b>Profesionalism:</b> instruire oferită de un instructor licențiat <a href="https://safesitter.org/" target="_blank">Safe Sitter®</a>',
                        black: '❌',
                        kiddo: '✅',
                    },
                    {
                        name : '<b>Siguranță:</b> curs de Prim Ajutor, certificare internațională <a href="http://redcross.md" target="_blank">Crucea Roșie</a>',
                        black: '❌',
                        kiddo: '✅',
                    },
                    {
                        name : '<b>Transparență:</b> rating și recenzii ale altor părinți care au beneficiat de serviciile aceleiași persoane',
                        black: '❌',
                        kiddo: '✅',
                    },
                    {
                        name : '<b>Rapid de găsit:</b> o listă de babysitteri de încredere doar după câteva click-uri',
                        black: '❌',
                        kiddo: '✅',
                    },
                    {
                        name : '<b>Fără angajament de lungă durată:</b> apelezi și plătești doar atunci când ai nevoie',
                        black: '❌',
                        kiddo: '✅',
                    },
                    {
                        name : '<b>Flexibilitate:</b> găsești ușor și rapid înlocuire în situații excepționale',
                        black: '❌',
                        kiddo: '✅',
                    },
                    {
                        name : '<b>Feedback:</b> oferă feedback pentru a îmbunătăți calitatea serviciilor pe care le primești',
                        black: '❌',
                        kiddo: '✅',
                    },
                ],

                testimonials: [
                    {
                        id    : 1,
                        image : require('../../src/assets/img/testimonials/diana.jpg'),
                        title : 'Diana U., mama lui Răzvan',
                        review: 'Recomand cu toată încrederea! Am avut plăcerea să o cunosc pe Maria în ziua când aveam nevoie de ajutor pentru câteva ore. Cu toate că am făcut rezervarea în ultimul moment, doar cu 3 ore înainte, m-a surprins cât de accesibil, rapid și de încredere este serviciul. Am cunoscut o persoana foarte drăguță, care s-a conectat cu fiul meu de 3 ani rapid și au petrecut bine timpul împreună! Este pentru prima dată când apelez la serviciile unui babysitter și nu din motiv că nu mi-aș fi dorit până acum, însă îmi părea imposibil să găsesc ocazional, pentru câteva ore, pe cineva de încredere cu care să îmi las copilul, așa cum majoritatea oferă servicii pe termen lung și cu un grafic stabil. Faptul că babysitterii au fost instruiți de fapt m-a și convins să apelez. Acesta este criteriul de bază ca să îmi pot lăsa copilul cu o persoana străină - să am încrederea că va putea cu blândețe și empatie să gestioneze orice situație. Cu siguranță voi apela de fiecare data când voi avea nevoie! Multumesc KIDDO pentru această oportunitate!',
                        score : 5.0,
                        date  : 'Februarie 2021',
                    },
                    {
                        id    : 2,
                        image : require('../../src/assets/img/testimonials/tudor.png'),
                        title : 'Tudor C., tatăl lui Cristi și Alex',
                        review: 'Am apelat la serviciile KIDDO când am avut nevoie urgent ca cineva să stea cu băiatul meu de 5 ani. Chiar daca eram sceptic la început dacă bona se va descurca din prima, am fost plăcut surprins să descopăr că Iulia s-a descurcat de minune și băiatul meu a fost foarte fericit după interacțiunea cu ea. Chiar și acum întreabă deseori când o să mai vină. E un serviciu extrem de util pentru părinți, pentru că ușurează foarte mult rutina zilnică, mai ales când apar situații neprevăzute de soluționat și aparent nu ai cu cine lăsa pe cei mici, ei uite că deja ai cu cine. Faptul că aceste bone sunt inițial instruite, iar proiectul a fost lansat de buna mea prietenă Diana Ivanov, a fost tot creditul de care am avut nevoie pentru a-mi lăsa copilul în grija unui străin. Merci KIDDO! ;)',
                        score : 5.0,
                        date  : 'Ianuarie 2021',
                    },
                    {
                        id    : 3,
                        image : require('../../src/assets/img/testimonials/rodica.jpg'),
                        title : 'Rodica S., mama Magdei',
                        review: 'Serviciul de babysitting per oră este o idee minunată! Uneori ai nevoie de doar câteva ore pentru tine, ocazional, iar atunci când bunicii sau rudele trăiesc departe sau nu sunt disponibile - este o adevărată salvare. Iulia a avut o conexiune instant cu copila noastra. S-au jucat, distrat, râs timp de 3 ore continuu. Copil fericit - mama mulțumită!',
                        score : 5.0,
                        date  : 'Ianuarie 2021',
                    },
                    {
                        id    : 4,
                        image : require('../../src/assets/img/testimonials/maria.jpg'),
                        title : 'Maria A., mama lui David',
                        review: 'Prima mea experiență cu KIDDO a fost utilă și plăcută. Utilă, pentru că mi-a satisfăcut necesitatea de a-mi desfășura activitatea planificată neavând preocuparea de a tine sub supraveghere copilul; și plăcută pentru că bona care m-a ajutat s-a dovedit a fi o persoană cu energie pozitivă, care a găsit repede o cale de comunicare și înțelegere cu copilul meu. Pentru mine foarte mult contează abordarea individuală pe care o promovați și transparența, iar acesta sunt doua atuuri foarte frumoase care mi-au dat încredere să-mi "las pe mîna cuiva străin" copilul.',
                        score : 5.0,
                        date  : 'Decembrie 2020',
                    },
                    {
                        id    : 5,
                        image : require('../../src/assets/img/testimonials/rita.png'),
                        title : 'Rita D., mama Mariei',
                        review: 'Sunt super mega încântată de platformă și de posibilitățile ei. Cu toate că deseori o ducem pe Maria la bunici, totuși uneori avem nevoie de babysitter pentru câteva ore, și dacă nu era KIDDO, nu știu ce ne făceam. Foarte mulțumită de operativitate, de gradul de pregătire al babysitterilor care au stat cu Maria. Recomand cu cea mai mare încredere!',
                        score : 5.0,
                        date  : 'Noiembrie 2021',
                    },
                    {
                        id    : 6,
                        image : require('../../src/assets/img/testimonials/olga.jpeg'),
                        title : 'Olga M., mama lui Alex',
                        review: 'Această platformă îmi vine în ajutor mereu când am nevoie, fie că am careva treburi, fie că vreau să-mi încarc bateriile. Cel mai mult apreciez faptul că fetele de la KIDDO au un bagaj mare de experiență și cunoștințe pe care pe împărtășesc cu drag. Astfel, sunt sigură că micuțul meu este pe mâini bune!',
                        score : 5.0,
                        date  : 'Octombrie 2020',
                    },
                ],

                countup: {
                    useEasing  : true,
                    useGrouping: true,
                    separator  : ',',
                    decimal    : '.',
                    prefix     : '',
                    suffix     : ''
                },
            }
        },

        methods: {
            handleRedirect() {
                if (this.isEdoositter) {
                    setTimeout(() => {
                        window.location.href = 'https://edoositter.com';
                    }, 3000)
                }
            },

            setExternalData() {
                this.$request.get('/api/reviews', {offset: 0, limit: 5}).then((results) => {
                    this.reviews = {
                        count: results.count,
                        items: results.items.items,
                        score: results.score,
                    };
                });

                this.$request.get('/api/stats').then((results) => {
                    this.numbers = {
                        hours    : results.provider_hours,
                        providers: results.provider_verified,
                        consumers: results.consumer_usage,
                    };
                });
            },
        },
    }
</script>
