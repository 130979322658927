<template>
    <div v-if="!isWebView() && isMobileDevice() && show" class="is-hidden-desktop">
        <div v-if="isMobileIos() || isMobileAndroid()" class="mx-2 my-2">
            <div class="columns is-mobile">
                <div class="column is-1"
                     style="display: flex;justify-content: center;align-items: center;flex-direction: column;align-content: center;">
                    <div>
                        <a href="#" @click.prevent="handleMobilePopupClose()">
                            <span class="mdi mdi-close"/>
                        </a>
                    </div>
                </div>
                <div class="column is-2" @click.prevent="handleMobileAppDownload()">
                    <figure class="image is-64x64">
                        <img src="../assets/img/mobile-app-icon.png" alt="mobile-app-icon">
                    </figure>
                </div>
                <div class="column is-7" @click.prevent="handleMobileAppDownload()">
                    <div class="has-text-weight-bold">KIDDO</div>
                    <Rating :score="5" :stars="5"/>
                    <div v-if="isMobileIos()" class="has-text-weight-light is-size-7">
                        Descarca aplicația din App Store
                    </div>
                    <div v-if="isMobileAndroid()" class="has-text-weight-light is-size-7">
                        Descarca aplicația din Google Play
                    </div>
                </div>
                <div class="column is-2"
                     style="display: flex;justify-content: center;align-items: center;flex-direction: column;align-content: center;">
                    <div>
                        <a href="#" @click.prevent="handleMobileAppDownload()">
                            <span class="mdi mdi-arrow-right"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import MobileMixin from "../mixins/MobileMixin";
    import Rating from "./Rating";

    export default {
        name: 'MobileAppBadge',

        components: {
            Rating
        },

        mixins: [
            MobileMixin,
            MobileMixin,
        ],

        data() {
            return {
                show: (!window.localStorage.getItem('KAPPDLDISS')),
            }
        },

        methods: {
            handleMobilePopupClose() {
                window.localStorage.setItem('KAPPDLDISS', '1');
                this.show = false;
            },

            handleMobileAppDownload() {
                if (this.isMobileAndroid()) {
                    window.open('https://play.google.com/store/apps/details?id=com.joinkiddo', '_blank');
                }
                if (this.isMobileIos()) {
                    window.open('https://apps.apple.com/md/app/kiddo/id1587593253', '_blank');
                }
            },
        },
    }
</script>
