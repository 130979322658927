<template>
    <div class="stars-rating is-flex is-align-content-center">
        <template v-for="(i, index) in stars" :key="index">
            <div :class="getStarIcon(i)"/>
        </template>
    </div>
</template>

<script>
    export default {
        name: 'Rating',

        props: {
            stars: {
                type    : Number,
                required: true,
                default : 5,
            },
            score: {
                type    : Number,
                required: true,
                default : 5,
            },
        },

        data() {
            return {}
        },

        methods: {
            getStarIcon(index) {
                let score = (this.score - Math.floor(this.score)) > 0.5 ? Math.round(this.score) : Math.floor(this.score);

                return (score >= index) ? 'mdi mdi-star has-text-warning' : 'mdi mdi-star-outline has-text-grey-light';
            },
        },
    }
</script>
