import getBrowserFingerprint from "get-browser-fingerprint";

const mixin = {
    methods: {
        track(event = 'page_view', params = {}) {
            if (process.env.VUE_APP_TRACKING_ENABLED) {
                let payload = {
                    ...params,
                    _fingerprint: getBrowserFingerprint(),
                    _environment: process.env.VUE_APP_ENV,
                    _referrer   : document.referrer,
                    _href       : window.location.href,
                    _app        : false,
                    ...this.getUTMParams(),
                }

                this.$request.post('/api/track', payload).then(() => {
                    // code...
                }).catch(() => {
                    // code...
                });
            }
        },

        getUTMParams() {
            const getUTMValue = (param) => {
                if (!window?.location?.search) {
                    return null;
                }

                let utmQuery = decodeURIComponent(window.location.search.substring(1));
                let utms = utmQuery.split('&');
                let params, i;

                if (!utms?.length) {
                    return null;
                }

                for (i = 0; i < utms.length; i++) {
                    params = utms[i].split('=');
                    if (params[0] === param) {
                        return params[1] === null ? null : params[1];
                    }
                }

                return null;
            }

            return {
                _ref         : getUTMValue('ref'),
                _utm_source  : getUTMValue('utm_source'),
                _utm_medium  : getUTMValue('utm_medium'),
                _utm_campaign: getUTMValue('utm_campaign'),
                _utm_content : getUTMValue('utm_content'),
                _utm_term    : getUTMValue('utm_term'),
            };
        },
    }
}

export default mixin;
