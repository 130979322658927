<template>
    <div class="maintenance has-background-danger-light" style="height: 100vh">
        <div id="section-hero">
            <div class="container is-max-desktop">
                <section class="hero is-halfheight">
                    <div class="hero-body">
                        <div class="has-text-centered">
                            <img src="../assets/img/logo-white.svg" class="mt-3 mb-6">

                            <div class="title is-size-2">
                                <div class="has-text-weight-bold has-text-danger">
                                    KIDDO
                                </div>
                            </div>

                            <div>
                                <img src="../assets/img/push-notification.svg">
                            </div>

                            <div class="has-text-left content">
                                <ol>
                                    <li>
                                        Intră în contul tau KIDDO. Folosește browserul tău preferat.
                                    </li>
                                    <li>
                                        Urmează instrucțiunea cum conectezi notificările.
                                    </li>
                                    <li>
                                        Dacă întâmpeni dificultăți de conectare a notificărilor,
                                        nu ezita să ne scrii in Chat sau prin formularul de contact
                                        https://app.kiddo.md/#/help
                                    </li>
                                </ol>
                            </div>
                        </div>


                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
    import RouteMixin from "../mixins/RouteMixin";

    export default {
        name: 'Maintenance',

        mixins: [
            RouteMixin,
        ],
    }
</script>
