<template>
    <nav class="navbar has-background-danger-light" role="navigation" aria-label="main navigation">
        <div class="navbar-brand has-background-danger-light">
            <a class="navbar-item" :href="getUrl()">
                <img src="../../assets/img/logo.svg" height="28" alt="logo" class="mr-2">
                <span class="has-text-weight-bold">KIDDO</span>
            </a>
            <a @click="toggle" role="button" class="navbar-burger" :class="(open) ? 'is-active' : ''" aria-label="menu"
               data-target="navbar">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
        </div>

        <div id="navbar" class="navbar-menu" :class="(open) ? 'is-active has-background-white' : ''">
            <div class="navbar-start">
                <router-link
                    v-if="!edoositter"
                    :to="{name: 'index'}"
                    tag="a"
                    active-class="is-active has-text-danger"
                    class="navbar-item"
                    exact
                >
                    Pentru părinți
                </router-link>
                <router-link
                    v-if="!edoositter"
                    :to="{name: 'providers'}"
                    tag="a"
                    active-class="is-active has-text-danger"
                    class="navbar-item"
                    exact
                >
                    Pentru babysitteri
                </router-link>
                <router-link
                    v-if="!edoositter"
                    :to="{name: 'about'}"
                    tag="a"
                    active-class="is-active has-text-danger"
                    class="navbar-item"
                    exact
                >
                    Despre KIDDO
                </router-link>
                <router-link
                    v-if="!edoositter"
                    :to="{name: 'questions'}"
                    tag="a"
                    active-class="is-active has-text-danger"
                    class="navbar-item"
                    exact
                >
                    Întrebări frecvente
                </router-link>
            </div>
            <div class="navbar-end">
                <div class="navbar-item" v-if="!edoositter">
                    <div class="field is-grouped">
                        <button @click="handleLoginClick()" class="button is-danger is-outlined is-fullwidth">
                            Intră în cont
                        </button>
                    </div>
                </div>
                <div class="navbar-item" v-if="!edoositter">
                    <div class="field is-grouped">
                        <button @click="handleRegisterClick()" class="button is-danger is-fullwidth">
                            Creează cont nou
                        </button>
                    </div>
                </div>
                <div class="navbar-item" v-if="edoositter">
                    <div class="field is-grouped">
                        <button @click="handleEdooSitterClick()" class="button is-danger is-fullwidth">
                            EdooSitter
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
    import RouteMixin from "../../mixins/RouteMixin";

    export default {
        name: 'Navigation',

        mixins: [
            RouteMixin,
        ],

        data() {
            return {
                open: false,
                edoositter: (process.env.VUE_APP_EDOOSITTER === 'true'),
            };
        },

        methods: {
            getUrl() {
                return process.env.VUE_APP_ROOT_URL;
            },

            toggle() {
                this.open = !this.open;
            }
        },
    }
</script>
