import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import boot from './boot';
import '../src/assets/css/main.css';

const app = createApp(App);

boot(app);

app.use(store).use(router).mount('#app');
