<template>
    <router-view/>
</template>

<script>
    import emitter from 'tiny-emitter/instance';
    import MobileMixin from "./mixins/MobileMixin";
    import TrackingMixin from "./mixins/TrackingMixin";
    import Maintenance from "./views/Maintenance";

    export default {
        name: 'App',

        components: {
            Maintenance
        },

        mixins: [
            MobileMixin,
            TrackingMixin,
        ],

        created() {
            emitter.on('on:router:afterEach', () => {
                this.track();
            });
        },
    }
</script>
