import moment from 'moment';

const LOCALE = 'ro';

const mixin = {
    methods: {
        getLocaleDate(date = null) {
            if (!date) {
                date = new Date();
            }

            moment.locale(LOCALE);

            return moment(date).toDate();
        },

        isToday(date) {
            let today = this.getLocaleDate();

            return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
        },

        isSameDate(date, next = this.getLocaleDate()) {
            moment.locale(LOCALE);
            return moment(next).isSame(date, 'day');
        },

        isSameWeek(date, next = this.getLocaleDate()) {
            moment.locale(LOCALE);
            return moment(next).isSame(date, 'week');
        },

        isSameMonth(date, next = this.getLocaleDate()) {
            moment.locale(LOCALE);
            return moment(next).isSame(date, 'month');
        },

        isSameYear(date, next = this.getLocaleDate()) {
            moment.locale(LOCALE);
            return moment(next).isSame(date, 'year');
        },

        addHoursToDate(date, hours, separator = '.', format = 'HH:mm') {
            return this.addHoursToDateRaw(date, hours, separator).format(format);
        },

        addHoursToDateRaw(date, hours, separator = '.') {
            moment.locale(LOCALE);

            let endDate   = moment(this.getLocaleDate(date));
            let hour      = hours.toString().split(separator);

            endDate.add(hour[0], 'hours');

            if (hour[1]) {
                endDate.add(this.formatString(hour[1]), 'minutes');
            }

            return endDate;
        },

        isOverdueDate(date) {
            let start = new Date(date);
            let today = new Date();

            return start.getDate() < today.getDate();
        },

        timeDuration(endDate, startDate) {
            moment.locale(LOCALE);

            return moment.duration(moment(endDate).diff(startDate));
        },

        timeDiffCalc(dateFuture, dateNow, format = 'LT') {
            moment.locale(LOCALE);

            return moment.utc(moment(dateFuture, 'DD/MM/YYYY HH:mm').diff(moment(dateNow, 'DD/MM/YYYY HH:mm'))).format(format)
        },

        formatDate(date, format = 'LL') {
            moment.locale(LOCALE);
            return moment(date).format(format);
        },

        formatRelativeDate(date) {
            moment.locale(LOCALE);
            return moment(date).fromNow();
        },

        formatUnixDate(timestamp, format = 'LL') {
            moment.locale(LOCALE);
            return moment.unix(timestamp).format(format);
        },

        formatRelativeUnixDate(timestamp) {
            moment.locale(LOCALE);
            return moment.unix(timestamp).fromNow();
        },

        formatYears(date) {
            moment.locale(LOCALE);
            return moment().diff(date, 'years');
        },

        formatTimeFrom(date) {
            moment.locale(LOCALE);
            return moment(date).from();
        },

        formatFrom(date, suffix = false) {
            moment.locale(LOCALE);

            return moment(date).from(new Date(), suffix);
        },

        formatHourToString(hour, separator = '.') {
            let data = hour.toString().split(separator);

            return `${data[0]}:${(data[1]) ? this.formatString(data[1]) : '00'}`;
        },

        formatHour(hour, separator = '.', abs = false) {
            let data   = hour.toString().split(separator);
            let string = `${data[0]}h`;

            if (data[1]) {
                if (abs) {
                    data[1] = Math.round((parseInt(data[1]) * 60) / 100);
                }
                string = `${string} ${this.formatString(data[1])}m`;
            }

            return string;
        },

        formatString(string) {
            return (string.length === 1) ? string + '0' : string;
        }
    }
}

export default mixin;
