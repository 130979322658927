import request from "./request";

const boot = (app) => {
    return {
        // Register boot files.
        // Run the code before the root Vue app instance is instantiated.
        $request: request(app),
    };
}

export default boot;
