import emitter from 'tiny-emitter/instance';
import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import Home from '../views/Home.vue';

const routes = [
    {
        path     : '/',
        name     : 'index',
        component: Home
    },
    {
        path     : '/babysitting',
        name     : 'providers',
        component: () => import(/* webpackChunkName: "providers" */ '../views/Providers.vue')
    },
    {
        path     : '/about',
        name     : 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path     : '/questions',
        name     : 'questions',
        component: () => import(/* webpackChunkName: "questions" */ '../views/FrecventQuestions.vue')
    },
    {
        path     : '/terms-of-use',
        name     : 'terms-of-use',
        component: () => import(/* webpackChunkName: "terms-of-use" */ '../views/TermsOfUse.vue')
    },
    {
        path     : '/privacy-policy',
        name     : 'privacy-policy',
        component: () => import(/* webpackChunkName: "privacy-policy" */ '../views/PrivacyPolicy.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    // history: createWebHashHistory(process.env.BASE_URL),
    routes
})

router.afterEach((to, from) => {
    emitter.emit('on:router:afterEach', {to, from});
});

export default router
