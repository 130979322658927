const mixin = {
    methods: {
        handleRedirect(to) {
            this.$router.push(to).catch(() => {});
        },

        handleSiteClick() {
            window.open('https://www.kiddo.md/', '_blank');
        },

        handleLoginClick() {
            window.open('https://app.kiddo.md/#/login', '_blank');
        },

        handleRegisterClick(params = null) {
            let url = 'https://app.kiddo.md/#/register';

            if (params) {
                url = url + '?' + params;
            }

            window.open(url, '_blank');
        },

        handleEdooSitterClick(params = null) {
            let url = 'https://edoositter.com';

            if (params) {
                url = url + '?' + params;
            }

            window.open(url, '_blank');
        },
    }
}

export default mixin;
