<template>
    <div class="container is-max-desktop">
        <div class="section has-text-white">
            <div class="columns">
                <div class="column">
                    <img src="../../assets/img/logo-white.svg" class="mr-1">
                    <div class="has-text-weight-bold mr-1">
                        KIDDO Moldova
                    </div>
                </div>
                <div class="column">
                    <div class="is-size-5 mb-3">
                        Social media
                    </div>

                    <div class="mr-3 is-size-6">
                        <a href="https://www.facebook.com/kiddomd" target="_blank" class="has-text-white">
                            <span class="icon"><i class="mdi mdi-facebook"></i></span>
                            Facebook
                        </a>
                    </div>
                    <div class="mr-3 is-size-6">
                        <a href="https://www.instagram.com/kiddo_md" target="_blank" class="has-text-white">
                            <span class="icon"><i class="mdi mdi-instagram"></i></span>
                            Instagram
                        </a>
                    </div>
                    <div class="mr-3 is-size-6">
                        <a href="https://www.youtube.com/channel/UCZ4FaBFWLSemRsoTWQKmY2g/videos" target="_blank"
                           class="has-text-white">
                            <span class="icon"><i class="mdi mdi-youtube"></i></span>
                            YouTube
                        </a>
                    </div>
                </div>

                <div class="column">
                    <div class="is-size-5 mb-3">
                        Contacte
                    </div>
                    <div>
                        <a href="tel:+37369807733" class="has-text-white">+37369807733</a>
                    </div>
                </div>

                <div class="column">
                    <a href="https://www.kiddo.md/terms-of-use" target="_blank" class="is-size-6 has-text-white">
                        Termenii și Condițiile
                    </a>
                    <a href="https://www.kiddo.md/privacy-policy" target="_blank" class="is-size-6 has-text-white">
                        Politica de Confidențialitate
                    </a>
                </div>
            </div>

            <div class="is-size-6">© 2022 Toate drepturile sunt rezervate.</div>
        </div>
    </div>
</template>

<script>
    import ViberIcon from "../icons/ViberIcon";

    export default {
        name      : 'Footer',

        components: {
            ViberIcon
        },
    }
</script>
