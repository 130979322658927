<template>
    <svg :width="width" :height="height" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m15.3828.15625c-.375488-.15625-.804688-.0698242-1.08984.216797-.314941.31488-.707031.70697-2.29297.70697-1.42822 0-2.50537.849548-3.29199 1.4696-.22168.174805-.543945.428711-.669434.508301-.125488-.0893554-.333008-.293457-.478516-.436523-.625-.613709-1.56934-1.54138-3.06006-1.54138-1.64648 0-2.53027-.444275-2.79297-.70697-.287109-.286621-.716797-.373047-1.08984-.216797-.373535.154785-.617188.51947-.617188.923767 0 2.80103.443848 6.62305 4.28223 7.4765 1.56494.347595 2.13232.763123 2.50732 1.03845.294434.21582.661133.484375 1.21045.484375.549316 0 .916016-.269043 1.21045-.484863.375-.274841.942383-.690857 2.50684-1.03845 3.83887-.852478 4.28271-4.67499 4.28271-7.47601 0-.404297-.243652-.768982-.617188-.923767zm-12.3828 3.98651s2.2793-.354004 3.33331.609131c.666687.60913.666687 1.2182.666687 1.82733-4 0-4-2.43646-4-2.43646zm6 2.43646c0-.609131 0-1.2182.666687-1.82733 1.05402-.963135 3.33331-.609131 3.33331-.609131s0 2.43646-4 2.43646z"
            transform="translate(0 2.921)"/>
    </svg>
</template>

<script>
    export default {
        name: 'IncognitoIcon',

        props: {
            width : {
                type    : Number,
                default : 35,
                required: false,
            },
            height: {
                type    : Number,
                default : 35,
                required: false,
            },
        },
    }
</script>
