<template>
    <svg :width="width" :height="height" viewBox="0 0 37 32" version="1.1" xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="logo-midnightblue" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path
                d="M35.966,11.299 C35.4929986,9.98502763 34.8426482,8.74185916 34.033,7.604 C34.0002443,7.55237903 33.9641683,7.5029415 33.925,7.456 C32.1327334,5.02775076 29.7506237,3.09686858 27.004,1.846 C25.6915927,1.23969337 24.3167294,0.778946503 22.904,0.472 C19.8276709,-0.189458644 16.6413601,-0.144325796 13.585,0.604 L13.585,0.604 L13.311,0.672 C5.752,2.662 0.245,8.842 0.245,16.161 L0.245,16.201 C0.315755196,19.0876969 1.25339456,21.8863291 2.936,24.233 C5.35893808,27.548865 8.82294646,29.9573701 12.775,31.074 C14.5194347,31.4494467 16.306616,31.587362 18.088,31.484 C20.015,31.324 22.033,30.606 22.388,29.135 C22.6857552,28.2213638 22.5100881,27.2194927 21.9192593,26.4616599 C21.3284304,25.7038271 20.3996685,25.2890911 19.441,25.355 C19.0596694,25.3481289 18.6790457,25.3194152 18.301,25.269 C19.441,24.619 19.783,23.656 19.321,23.103 C18.568,22.191 17.571,23.183 16.385,23.503 C13.985,24.159 12.776,23.543 10.867,21.69 C5.567,16.559 7.19,10.214 12.218,9.679 C16.271,9.246 17.987,12.615 18.489,13.362 C19.378,12.171 21.089,9.423 24.857,9.799 C29.207,10.238 30.928,14.964 27.896,19.553 C27.3789128,20.3269736 26.7808742,21.0436813 26.112,21.691 C24.7249542,23.0381687 23.0809864,24.0924303 21.278,24.791 C23.416,25.361 23.832,27.715 23.496,29.255 C23.2508774,30.3700465 22.4497098,31.2807562 21.375,31.666 C24.1473625,31.2785798 26.8059338,30.3071787 29.175,28.816 C33.7520518,26.0903739 36.6039502,21.2008114 36.723,15.875 C36.7090566,14.3168247 36.4512691,12.770437 35.959,11.292 L35.966,11.299 Z"
                id="Path_1" fill="#0F225B" fill-rule="nonzero"></path>
            <path
                d="M28.121,13.459 C27.4786382,11.666291 25.7203386,10.5213517 23.821,10.659 C20.902,10.659 18.559,15.22 18.457,15.374 C18.252,15.18 16.017,10.659 13.093,10.659 C11.1936614,10.5213517 9.4353618,11.666291 8.793,13.459 C8.069,15.694 9.107,18.453 11.564,20.841 C13.411,22.625 14.004,22.603 14.831,22.603 C16.803,22.603 17.807,21.212 19.312,21.851 C20.1095747,22.1822996 20.6024115,22.9902288 20.532,23.851 C22.3401303,23.2081324 23.9875651,22.1812575 25.361,20.841 C27.836,18.453 28.845,15.694 28.121,13.459 Z"
                id="Path_2" fill="#EA3518" fill-rule="nonzero"></path>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'LogoIcon',

        props: {
            width : {
                type    : Number,
                default : 35,
                required: false,
            },
            height: {
                type    : Number,
                default : 35,
                required: false,
            },
        },
    }
</script>
